<template>
  <div class="c-app bg-login flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Input Your Account Login</p>
                  <CAlert
                    :show.sync="dismissCountDown"
                    closeButton
                    color="info"
                    fade
                  >
                    Maaf! Username dan Password tidak boleh kosong...
                  </CAlert>
                  <CInput
                    placeholder="Username"
                    v-model="username"
                    autofocus = "true"
                    autocomplete="username email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    v-model="password"
                    v-on:keyup.enter="loginNow"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        v-on:click="loginNow"
                        >Login</CButton
                      >
                    </CCol>
                    <CCol col="6" class="text-right">
                      <!-- <CButton color="link" class="px-0"
                        >Forgot password?</CButton
                      > -->
                      <!-- <CButton color="link" to="/register" class="px-0"
                        >Daftar Sekarang!</CButton> -->
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <CRow>
                  <CCol>
                    <img
                  :src="require('../../assets/images/kalbar.png')"
                  style="width: 90px; margin: 10 auto;padding:10;border-radius: 10px;" />
                  </CCol>
                  <!-- <CCol>
                    <img
                  :src="require('../../assets/images/polda.png')"
                  style="width: 90px; margin: 10 auto" />
                  </CCol> -->
                </CRow>
                <!-- <img
                  :src="require('../../assets/images/bitqueen.jpg')"
                  style="width: 110px; margin: 10 auto"
                /> -->
                <h2>SEMARI</h2>
                <p>Sistem Informasi Geografis Dinas Perumahan Rakyat dan Kawasan Permukiman Provinsi Kalimantan Barat</p>
                <!-- <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Login Sekarang!
                </CButton> -->
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CModal title="Warning" :show.sync="myModal" size="sm">
      Maaf! Username dan Password tidak boleh kosong...
    </CModal>
    <CModal title="Warning" :show.sync="myModal2" size="sm">
      Berhasil! Anda berhasil login...
    </CModal>
  </div>
</template>
<style scoped>
  .bg-login{
    background-image: url('~@/assets/images/bg_login.jpeg');
    background-size: cover;
    background-position: center center;
  }
</style>
<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
    return {
      tabs: [],
      myModal: false,
      myModal2: false,
      users: "",
      dismissCountDown: 0,
      username: "",
      password: "",
      rcvr: "",
      activeTab: 1,
    };
  },
  mounted(){
    this.$store.commit('setLogin', false);
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    loginNow: function (event) {
      // Simple POST request with a JSON body using axios
      const user = {
        username: this.username,
        password: this.password,
      };
      // alert(localStorage.getItem("jwt"));
      if (
        (this.username == null || this.username == "",
        this.password == null || this.password == "")
      ) {
        this.myModal = true;
      } else {
        axios
          .post(
             process.env.VUE_APP_BASE_URL+"login",
            user
          )
          .then((response) => {
            // console.log(response);
            // alert(response.data);
            // this.tabs = response.data;/
            if (response.data.status == "ok") {
              localStorage.setItem("user", JSON.stringify(response.data.user));
              localStorage.setItem("jwt", response.data.access_token);
              this.users = JSON.stringify(response.data.user);
              this.$store.commit('setUsers', this.users);
              this.$store.commit('setLogin', true);
              this.$router.push("/dashboard");

              this.myModal2 = true;
            } else {
              this.myModal = true;
            }
          });
      }
    },
  },
};
</script>
